
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'width': '21',
                'height': '23',
                'viewBox': '0 0 21 23',
                'fill': 'currentColor',
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'className': 'icon'
            }, _createElement('path', { 'd': 'M14.398 14.483 19 19.514l-1.186 1.014-4.59-5.017a8.317 8.317 0 0 1-4.888 1.578C3.732 17.089 0 13.369 0 8.779S3.732.472 8.336.472c4.603 0 8.335 3.72 8.335 8.307a8.265 8.265 0 0 1-2.273 5.704ZM8.336 15.53c3.74 0 6.772-3.022 6.772-6.75 0-3.729-3.031-6.75-6.772-6.75S1.563 5.051 1.563 8.78c0 3.728 3.032 6.75 6.773 6.75Z' })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]